import {Injectable, Injector} from '@angular/core';
import {Observable, of, throwError,} from "rxjs";
import {catchError, switchMap, timeout} from "rxjs/operators";
import {HttpClient} from '@angular/common/http';
import {AlertController} from "@ionic/angular";
import {Cassetta, CassettaVCategory, CassettaVTypeCode, CassettaVType,
        CassettaStatus} from '../../models/cassetta/cassetta.model';
import {AggregatoCassette, AggregatoStatus} from '../../models/cassetta/aggregato.cassette.model';
import {Lock} from '../../models/lock.model';
import {AppStorage} from "../app-storage/app-storage.service";
import {Authentication} from "../authentication/authentication.service";
import {EnvironmentService} from '../environment/environment.service';
import {SpinnerService} from "../spinner/spinner.service";
import {Test} from '../../util/TestUtil';

@Injectable()
export class CassettaService {

  auth: Authentication;

  aggregato: AggregatoCassette;


  public static readonly CASSETTE_MODIFICATE = 'CASSETTE_MODIFICATE';
  public static readonly MODAL_CASSETTE = 'MODAL_CASSETTE';

  constructor(private alertCtrl: AlertController,private appStorage: AppStorage,
              private env: EnvironmentService,public httpClient: HttpClient,
              public injector: Injector, private spinnerService: SpinnerService) {
    setTimeout(() => { this.auth = injector.get(Authentication) }, 1);
  }

  checkCassetteAggiunte(listCassette: Cassetta[]) {
    const cassetteAggiunte = {};
    for (let c of listCassette) {
      cassetteAggiunte[c.vTypeCode] = true;
    }
    return cassetteAggiunte;
  }

  getDefaultCassette(vCategory: CassettaVCategory, memberId: string): Observable<Cassetta[]> {
    let listCassette: Cassetta[] = [];
    switch (vCategory) {
      case CassettaVCategory.social:{
        let facebook = new Cassetta();
        facebook.init(CassettaVCategory.social, CassettaVTypeCode.facebook, CassettaVType.facebook, memberId);
        facebook.vDataGrabbed = true;

        let instagram = new Cassetta();
        instagram.init(CassettaVCategory.social, CassettaVTypeCode.instagram, CassettaVType.instagram, memberId);
        facebook.vDataGrabbed = false;

        let twitter = new Cassetta();
        twitter.init(CassettaVCategory.social, CassettaVTypeCode.twitter, CassettaVType.twitter, memberId);

        let linkedin = new Cassetta();
        linkedin.init(CassettaVCategory.social, CassettaVTypeCode.linkedin, CassettaVType.linkedin, memberId);

        listCassette.push(facebook, instagram, twitter, linkedin);
        break;
      }

      case CassettaVCategory.account:{
        let google = new Cassetta();
        google.init(CassettaVCategory.account, CassettaVTypeCode.google, CassettaVType.google, memberId);

        let apple = new Cassetta();
        apple.init(CassettaVCategory.account, CassettaVTypeCode.apple, CassettaVType.apple, memberId);

        listCassette.push(google, apple);
        break;
      }

      case CassettaVCategory.loyalty:{
        let carrefour = new Cassetta();
        carrefour.init(CassettaVCategory.loyalty, CassettaVTypeCode.carrefour, CassettaVType.carrefour, memberId);

        let conad = new Cassetta();
        conad.init(CassettaVCategory.loyalty, CassettaVTypeCode.conad, CassettaVType.conad, memberId);

        let coop = new Cassetta();
        coop.init(CassettaVCategory.loyalty, CassettaVTypeCode.coop, CassettaVType.coop, memberId);

        let esselunga = new Cassetta();
        esselunga.init(CassettaVCategory.loyalty, CassettaVTypeCode.esselunga, CassettaVType.esselunga, memberId);

        let gigante = new Cassetta();
        gigante.init(CassettaVCategory.loyalty, CassettaVTypeCode.gigante, CassettaVType.gigante, memberId);

        let iper = new Cassetta();
        iper.init(CassettaVCategory.loyalty, CassettaVTypeCode.iper, CassettaVType.iper, memberId);

        let unes = new Cassetta();
        unes.init(CassettaVCategory.loyalty, CassettaVTypeCode.unes, CassettaVType.unes, memberId);

        let auchan = new Cassetta();
        auchan.init(CassettaVCategory.loyalty, CassettaVTypeCode.auchan, CassettaVType.auchan, memberId);

        let ikeaFamily = new Cassetta();
        ikeaFamily.init(CassettaVCategory.loyalty, CassettaVTypeCode.ikeaFamily, CassettaVType.ikeaFamily, memberId);

        let decathlon = new Cassetta();
        decathlon.init(CassettaVCategory.loyalty, CassettaVTypeCode.decathlon, CassettaVType.decathlon, memberId);

        let mediaworld = new Cassetta();
        mediaworld.init(CassettaVCategory.loyalty, CassettaVTypeCode.mediaworld, CassettaVType.mediaworld, memberId);

        let laTuaCard = new Cassetta();
        laTuaCard.init(CassettaVCategory.loyalty, CassettaVTypeCode.laTuaCard, CassettaVType.laTuaCard, memberId);

        let panorama = new Cassetta();
        panorama.init(CassettaVCategory.loyalty, CassettaVTypeCode.panorama, CassettaVType.panorama, memberId);

        let coin = new Cassetta();
        coin.init(CassettaVCategory.loyalty, CassettaVTypeCode.coin, CassettaVType.coin, memberId);

        let unieuro = new Cassetta();
        unieuro.init(CassettaVCategory.loyalty, CassettaVTypeCode.unieuro, CassettaVType.unieuro, memberId);

        let acquaESapone = new Cassetta();
        acquaESapone.init(CassettaVCategory.loyalty, CassettaVTypeCode.acquaESapone, CassettaVType.acquaESapone, memberId);

        let feltrinelli = new Cassetta();
        feltrinelli.init(CassettaVCategory.loyalty, CassettaVTypeCode.feltrinelli, CassettaVType.feltrinelli, memberId);

        let pewex = new Cassetta();
        pewex.init(CassettaVCategory.loyalty, CassettaVTypeCode.pewex, CassettaVType.pewex, memberId);

        let cisalfaSport = new Cassetta();
        cisalfaSport.init(CassettaVCategory.loyalty, CassettaVTypeCode.cisalfaSport, CassettaVType.cisalfaSport, memberId);

        let md = new Cassetta();
        md.init(CassettaVCategory.loyalty, CassettaVTypeCode.md, CassettaVType.md, memberId);

        listCassette.push(carrefour, conad, coop, esselunga, gigante, iper, unes, auchan, ikeaFamily, decathlon, mediaworld, laTuaCard, panorama,
          coin, unieuro, acquaESapone, feltrinelli, pewex, cisalfaSport, md);
        break;
      }

      case CassettaVCategory.ecommerce:{
        let amazon = new Cassetta();
        amazon.init(CassettaVCategory.ecommerce, CassettaVTypeCode.amazon, CassettaVType.amazon, memberId);
        listCassette.push(amazon);
        break;
      }
      default:
        break;
    }
    return of(listCassette);
  }

  getCassette(userId: string, vCategory?: CassettaVCategory): Observable<Cassetta[]> {
    let params = {
      userId: userId
    };

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    if (vCategory != null) {
      params['vCategory'] = vCategory;
    }

    if (Test.TEST_SOCIAL && vCategory == CassettaVCategory.social) {
      let res: Cassetta[] = [];

      let facebook = new Cassetta();
      facebook.init(CassettaVCategory.social, CassettaVTypeCode.facebook, CassettaVType.facebook, 'ginetto');
      facebook.vAdditionalData = {
        first_name: 'Mario',
        last_name: 'Rossi',
        full_name: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }

      facebook.vGrants['grantUno'] = {
        title: 'Lista amici',
        enabled: true
      };
      facebook.vGrants['grantDue'] = {
        title: 'Interessi',
        enabled: true
      };
      facebook.vGrants['grantTre'] = {
        title: 'Posizione',
        enabled: true
      };
      facebook.vStatus = CassettaStatus.active;
      facebook.vDataGrabbed = true;
      facebook.vId = 1;

      res.push(facebook);

      let twitter = new Cassetta();
      twitter.init(CassettaVCategory.social, CassettaVTypeCode.twitter, CassettaVType.twitter, 'ginetto');
      twitter.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }

      twitter.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      twitter.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      twitter.vStatus = CassettaStatus.active;
      twitter.vDataGrabbed = true;
      twitter.vId = 1;

      res.push(twitter);

      let instagram = new Cassetta();
      instagram.init(CassettaVCategory.social, CassettaVTypeCode.instagram, CassettaVType.instagram, 'ginetto');
      instagram.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };
      instagram.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      instagram.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      instagram.vId = 2;
      instagram.vStatus = CassettaStatus.inactive;
      instagram.vDataGrabbed = false;
      res.push(instagram);

      let linkedin = new Cassetta();
      linkedin.init(CassettaVCategory.social, CassettaVTypeCode.linkedin, CassettaVType.linkedin, 'ginetto');
      linkedin.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };
      linkedin.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      linkedin.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      linkedin.vId = 2;
      linkedin.vStatus = CassettaStatus.inactive;
      linkedin.vDataGrabbed = false;

      res.push(linkedin);

      return of(res);

    } else if (Test.TEST_ACCOUNT && vCategory == CassettaVCategory.account) {
      let res: Cassetta[] = [];

      let google = new Cassetta();
      google.init(CassettaVCategory.account, CassettaVTypeCode.google, CassettaVType.google, 'ginetto');
      google.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }
      google.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      google.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      google.vId = 2;
      google.vStatus = CassettaStatus.inactive;
      google.vDataGrabbed = false;

      res.push(google);

      let apple = new Cassetta();
      apple.init(CassettaVCategory.account, CassettaVTypeCode.apple, CassettaVType.apple, 'ginetto');
      apple.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };
      apple.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      apple.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      apple.vId = 2;
      apple.vStatus = CassettaStatus.inactive;
      apple.vDataGrabbed = false;

      res.push(apple);

      return of(res);

    } else if (Test.TEST_LOYALTY && vCategory == CassettaVCategory.loyalty) {
      let res: Cassetta[] = [];

      let carrefour = new Cassetta();
      carrefour.init(CassettaVCategory.ecommerce, CassettaVTypeCode.carrefour, CassettaVType.carrefour, 'ginetto');
      carrefour.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };
      carrefour.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      carrefour.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      carrefour.vId = 2;
      carrefour.vStatus = CassettaStatus.inactive;
      carrefour.vDataGrabbed = false;

      res.push(carrefour);

      let conad = new Cassetta();
      conad.init(CassettaVCategory.loyalty, CassettaVTypeCode.conad, CassettaVType.conad, 'ginetto');
      conad.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }
      conad.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      conad.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      conad.vId = 2;
      conad.vStatus = CassettaStatus.inactive;
      conad.vDataGrabbed = false;

      res.push(conad);

      let coop = new Cassetta();
      coop.init(CassettaVCategory.loyalty, CassettaVTypeCode.coop, CassettaVType.coop, 'ginetto');
      coop.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }
      coop.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      coop.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      coop.vId = 2;
      coop.vStatus = CassettaStatus.inactive;
      coop.vDataGrabbed = false;
      res.push(coop);

      let esselunga = new Cassetta();
      esselunga.init(CassettaVCategory.loyalty, CassettaVTypeCode.esselunga, CassettaVType.esselunga, 'ginetto');
      esselunga.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }
      esselunga.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      esselunga.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      esselunga.vId = 2;
      esselunga.vStatus = CassettaStatus.inactive;
      esselunga.vDataGrabbed = false;
      res.push(esselunga);

      let gigante = new Cassetta();
      gigante.init(CassettaVCategory.loyalty, CassettaVTypeCode.gigante, CassettaVType.gigante, 'ginetto');
      gigante.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      }
      gigante.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      gigante.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      gigante.vId = 2;
      gigante.vStatus = CassettaStatus.inactive;
      gigante.vDataGrabbed = false;

      res.push(gigante);

      let iper = new Cassetta();
      iper.init(CassettaVCategory.loyalty, CassettaVTypeCode.iper, CassettaVType.iper, 'ginetto');
      iper.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };
      iper.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      iper.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      iper.vId = 2;
      iper.vStatus = CassettaStatus.inactive;
      iper.vDataGrabbed = false;
      res.push(iper);

      let unes = new Cassetta();
      unes.init(CassettaVCategory.loyalty, CassettaVTypeCode.unes, CassettaVType.unes, 'ginetto');
      unes.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };

      unes.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      unes.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      unes.vId = 2;
      unes.vStatus = CassettaStatus.inactive;
      unes.vDataGrabbed = false;

      res.push(unes);

      return of(res);

    } else if (Test.TEST_ECOMMERCE && vCategory == CassettaVCategory.ecommerce) {
      let res: Cassetta[] = [];

      let amazon = new Cassetta();
      amazon.init(CassettaVCategory.ecommerce, CassettaVTypeCode.amazon, CassettaVType.amazon, 'ginetto');
      amazon.vAdditionalData = {
        firstName: 'Mario',
        lastName: 'Rossi',
        fullName: 'Mario Rossi',
        email: 'mario.rossi@test.it'
      };
      amazon.vGrants['grantUno'] = {
        title: 'uno',
        enabled: true
      };
      amazon.vGrants['grantDue'] = {
        title: 'due',
        enabled: true
      };
      amazon.vId = 2;
      amazon.vStatus = CassettaStatus.inactive;
      amazon.vDataGrabbed = false;

      res.push(amazon);

      return of(res);
    }

    return this.httpClient.get<Cassetta[]>(this.env.GET_CASSETTE, {params: params, headers: headers}).pipe(timeout(20000),
      catchError(e => {
        if (e.name === "TimeoutError") {
          this.spinnerService.stop();
          this.alertHere()
        }
        return throwError(() => e);
      }));
  }


  async alertHere() {
    let alert = await this.alertCtrl.create({
      header: 'Attenzione',
      subHeader: this.env.GENERIC_ERROR_MESSAGE,
      buttons: ['Ok']
    });
    await alert.present();
  }

  getLocks(memberId: string): Observable<Lock[]> {
    if (Test.TEST_RIGHTS) {
      let res:any[] = [];
      let facebook: Lock = {
        vId: 1,
        vTypeCode: CassettaVTypeCode.facebook,
        vType: CassettaVType.facebook,
        vCategory: CassettaVCategory.social,
        vLockMarketing: true,
        vLockThirdParty: false,
        vStatus: CassettaStatus.active,
        vExportRequested: false
      }
      res.push(facebook);
      let google: Lock = {
        vId: 2,
        vTypeCode: CassettaVTypeCode.google,
        vType: CassettaVType.google,
        vCategory: CassettaVCategory.account,
        vLockMarketing: false,
        vLockThirdParty: false,
        vStatus: CassettaStatus.active,
        vExportRequested: false
      }
      res.push(google);
      let coop: Lock = {
        vId: 3,
        vTypeCode: CassettaVTypeCode.coop,
        vType: CassettaVType.coop,
        vCategory: CassettaVCategory.loyalty,
        vLockMarketing: true,
        vLockThirdParty: true,
        vStatus: CassettaStatus.active,
        vExportRequested: false
      }
      res.push(coop);
      let amazon: Lock = {
        vId: 4,
        vTypeCode: CassettaVTypeCode.amazon,
        vType: CassettaVType.amazon,
        vCategory: CassettaVCategory.ecommerce,
        vLockMarketing: false,
        vLockThirdParty: true,
        vStatus: CassettaStatus.active,
        vExportRequested: false
      }
      res.push(amazon);
      return of(res);
    }

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      userId: memberId.toString()
    };

    return this.httpClient.get<Lock[]>(this.env.GET_LOCKS, {params: params, headers: headers});
  }

  setAttribute(vaultId: number, attributeName: string, value: string, userId: string = ""): Observable<any> {

    if (Test.TEST_RIGHTS) {
      return of('Id: ' + vaultId + 'attribute: ' + attributeName + 'Value: ' + value)
    }
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      id:vaultId.toString(),
      attribute: attributeName,
      value: value
    }
    if (userId) {
      return this.httpClient.put<Lock[]>(this.env.VAULT,null,{params: params, headers: headers})
        .pipe(
          switchMap(response => {
            return this.getAggregato(userId);
          })
        );
    } else {
      return this.httpClient.put<Lock[]>(this.env.VAULT,null,{params: params, headers: headers})

    }

  }

  saveCassetta(cassetta: Cassetta): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let memberId = cassetta.userId;
    if (Test.TEST_SOCIAL && cassetta.vCategory == CassettaVCategory.social || Test.TEST_ACCOUNT && cassetta.vCategory == CassettaVCategory.account || Test.TEST_LOYALTY && cassetta.vCategory == CassettaVCategory.loyalty) {
      return of(cassetta);
    }
    if (!cassetta.vId) {
      return this.httpClient.post<Cassetta>(this.env.SAVE_CASSETTA, cassetta, {headers: headers})
        .pipe(
          switchMap(response => {
            return this.getAggregato(memberId);
          })
        );
    } else {
      return this.httpClient.put<Cassetta>(this.env.SAVE_CASSETTA, cassetta, {params: {vault:cassetta.vId.toString()}, headers: headers})
        .pipe(
          switchMap(response => {
            return this.getAggregato(memberId);
          })
        );
    }
  }

  saveCassettaAndGetResponse(cassetta: Cassetta): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };


    if (!cassetta.vId) {
      console.log("asking for cassetta");
      return this.httpClient.post<Cassetta>(this.env.SAVE_CASSETTA, cassetta, {headers: headers})
        .pipe(
          switchMap(response => {
            return of(response)
          })
        );
    } else {
      return this.httpClient.put<Cassetta>(this.env.SAVE_CASSETTA, cassetta, {params: {vault:cassetta.vId.toString()}, headers: headers})
        .pipe(
          switchMap(response => {
            return of(response)
          })
        );
    }
  }

  deleteCassetta(cassetta: Cassetta) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let memberId = cassetta.userId;
    let params = {
      id:cassetta.vId.toString()
    }
    return this.httpClient.delete<Cassetta>(this.env.VAULT, {params: params, headers: headers})
      .pipe(
        switchMap(response => {
          return this.getAggregato(memberId);
        })
      );
  }



  getAggregato(member_id: string): Observable<AggregatoCassette> {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    if (Test.TEST_AGGREGATO) {
      let aggregato: AggregatoCassette = {
        total: 12,
        account: {
          count: 3,
          status: AggregatoStatus.grabbed,
          grabbed: 0,
          vTypeCode: [],
          grants: 10
        },
        social: {
          count: 3,
          status: AggregatoStatus.active,
          grabbed: 0,
          vTypeCode: [],
          grants: 2
        },
        ecommerce: {
          count: 6,
          status: AggregatoStatus.empty,
          grabbed: 0,
          vTypeCode: [],
          grants: 6
        },
        loyalty: {
          count: 0,
          status: AggregatoStatus.grabbed,
          grabbed: 0,
          vTypeCode: [],
          grants: 7
        },
      };
      this.aggregato = aggregato;
      return of(aggregato);
    }


    // RIMETTERE PARAMS QUANDO AVREMO IL DB!
    return this.httpClient.get<AggregatoCassette>(this.env.GET_AGGREGATO, {headers: headers})
      .pipe(
        switchMap(response => {
          return this.saveAggregato(response);
        })
      );
  }

  getAggregatoInMemory(): AggregatoCassette {
    return this.aggregato;
  }

  hasMajorCassetta(): boolean {
    if (this.aggregato.social.count > 0) return true;
    else if (this.aggregato.account.count > 0) return true;
    else if (this.aggregato.ecommerce.count > 0) return true;
    else if (this.aggregato.loyalty.count > 0) return true;
    else return false;
  }

  saveAggregato(aggregato: AggregatoCassette): Observable<any> {
    const aggregatoObservable = new Observable((observer) => {

      this.aggregato = aggregato;

      this.appStorage.setItem(AppStorage.AGGREGATO, aggregato).then(res => {
        observer.next(aggregato);
        observer.complete();
      }, error => {
        console.error('error save aggregato')
        observer.error(error);
        observer.complete();
      })

    });

    return aggregatoObservable;
  }

  /* Metodo richiamato dopo l'aggiunta di una cassetta per verificare che si la prima */
  isFirstCassetta(vCategory: string) {
    if (this.aggregato == undefined) {
      return false;
    }
    let categoria = this.aggregato[vCategory];
    if (categoria == undefined) {
      return false;
    }
    return categoria.count == 1;
  }

  hasAnyCassetta(): boolean {
    let hasCassette = false;
    let userInfo = this.auth.getUserInfoInMemory();
    if (userInfo.has_interests || userInfo.survey_completed ||
      this.aggregato.account.count > 0 || this.aggregato.ecommerce.count > 0 ||
      this.aggregato.loyalty.count > 0 || this.aggregato.social.count > 0) {
      hasCassette = true;
    }
    return hasCassette;
  }

}
