import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from "./components/components.module";
import { Authentication } from './services/authentication/authentication.service';
import { AppStateService } from './services/app-state/app-state.service';
import { AppStorage } from './services/app-storage/app-storage.service';
import { CassettaService } from './services/cassetta/cassetta.service';
import { ChallengeService } from './services/challenge/challenge.service';
import { CustomHttpInterceptor} from './services/custom-http-interceptor/custom-http-interceptor.service';
import { DocumentService } from './services/document/document.service';
import { EncryptionService } from './services/encryption/encryption.service';
import { EnvironmentService } from './services/environment/environment.service';
import { EventsService } from './services/events/events.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { GeoAutocompleteService} from './services/geo-autocomplete/geo-autocomplete.service';
import { MailService } from './services/mail/mail.service';
import { MemberPreferenceService } from './services/member-preference/member-preference.service';
import { MyInAppBrowserService } from './services/my-in-app-browser/my-in-app-browser.service';
import { MyPlatformService } from './services/my-platform/my-platform.service';
import { NationService } from './services/nation/nation.service';
import { OfferService } from './services/offer/offer.service';
import { PopupUtilService } from './services/popup-util/popup-util.service';
import { PopupRemoveAvatarComponent } from './pages/popups/popup-remove-avatar/popup-remove-avatar.page';
import { PopupDeleteUserPage } from './pages/popups/popup-delete-user/popup-delete-user.page';
import { PopupVincitePage } from './pages/popups/popup-vincite/popup-vincite.page';
import { PopupSalvadanaioPage } from './pages/popups/popup-salvadanaio/popup-salvadanaio.page';
import { PopupOffertePage } from './pages/popups/popup-offerte/popup-offerte.page';
import { PopupComplimentiOfferPage } from './pages/popups/popup-complimenti-offer/popup-complimenti-offer.page';
import { PopupUltimoPermessoPage } from './pages/popups/popup-ultimo-permesso/popup-ultimo-permesso.page';
import { UploadProcessModalPage } from './pages/upload-process-modal/upload-process-modal.page';
import { PunteggioService } from './services/punteggio/punteggio.service';
import { ProvinceService } from './services/province/province.service';
import { PushUtil } from './services/push-util/push-util.service';
import { RewardService } from './services/reward/reward.service';
import { SocialService } from './services/social/social.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { UserService } from './services/user/user.service';
import { UtilityService } from './services/utility/utility.service';
import { RssService } from './services/rss/rss.service';


@NgModule({
  declarations: [AppComponent,
        PopupRemoveAvatarComponent,
        PopupDeleteUserPage,
        PopupVincitePage,
        PopupSalvadanaioPage,
        PopupOffertePage,
        PopupComplimentiOfferPage,
        PopupUltimoPermessoPage,
        UploadProcessModalPage,      ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    IonicModule.forRoot({swipeBackEnabled: false }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    AppStateService,
    AppStorage,
    Authentication,
    CassettaService,
    ChallengeService,
    DocumentService,
    EncryptionService,
    EnvironmentService,
    EventsService,
    FirebaseService,
    GeoAutocompleteService,
    MailService,
    MemberPreferenceService,
    MyInAppBrowserService,
    MyPlatformService,
    NationService,
    OfferService,
    PopupUtilService,
    ProvinceService,
    PunteggioService,
    PushUtil,
    RewardService,
    SocialService,
    SocialSharing,
    SpinnerService,
    UserService,
    UtilityService,
    RssService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
